@import "https://cdn.jsdelivr.net/npm/bulma@0.9.4/css/bulma.min.css";

body {
  background-color: #e6e5e5;
}

:root {
  --spaceXS: 1rem;
  --spaceSM: 2rem;
  --spaceMD: 3rem;
  --spaceLG: 6rem;
  --spaceXL: 12rem;
}

.box {
  margin-bottom: var(--spaceSM) !important;
}

.localized-cookie-configuration {
  border-bottom: 1px solid black;
}

.localized-cookie-configuration input, .localized-cookie-configuration span {
  padding-inline: var(--spaceXS);
}

.title {
  z-index: 2;
}

.icon {
  height: 100%;
  width: 50px;
  float: right;
}